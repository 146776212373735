<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            disabled
            icon
            class="btn-primary"
            color="primary"
            @click="toggleImportDialog(true)">
            <v-icon small>fas fa-import</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="totalItems"
              :options.sync="pagination"
              :loading="loading"
              :items-per-page="20"
              style="min-height: 600px"
              sort-by="createdAt"
              :sort-desc="false"
              :footer-props="{'items-per-page-options': itemsPerPage}"
              class="elevation-1">

              <!-- Type -->
              <template v-slot:item.customType="{item}">
                {{ item.customType }}
              </template>

              <!-- File Name -->
              <template v-slot:item.filename="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.filename }}
                    </span>
                  </template>
                  <span>{{ item.originalname }}</span>
                </v-tooltip>
              </template>

              <!-- Date -->
              <template v-slot:item.createdAt="{item}">
                {{ formatDate(item.createdAt) }}
              </template>

              <!-- Action -->
              <template v-slot:item.actions="{item}">
                <v-btn icon @click="download(item)" :loading="item.id === isLoadingDownloadFileId">
                  <v-icon small>fas fa-download</v-icon>
                </v-btn>

                <v-btn icon @click="toggleDeleteDialog(true, item)">
                  <v-icon small>fas fa-trash</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :label="currentFile.customType"
      @close="toggleDeleteDialog(false)"
      @submit="deleteFile"
    />
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";
import formatter        from "@/mixins/formatter";

export default {
  name: "SupplierFilesTab",

  components: {
    DeleteDialog: () => import("@/components/Common/DeleteDialog")
    // Skeleton: () => import("@/components/Common/Cards/Skeleton"),
  },

  mixins: [vuetifyDataTable, formatter],

  props: {
    supplier: { type: Object, default: () => null, required: true },

  },

  data() {
    return {

      showDialog: {
        import: false,
        edit: false,
        delete: false
      },

      headers: [
        {
          text: this.$t('common.files.datatable.headers.customType'),
          value: "customType"
        },
        {
          text: this.$t('common.files.datatable.headers.filename'),
          value: "filename"
        },
        {
          text: this.$t('common.files.datatable.headers.createdAt'),
          value: "createdAt"
        },
        {
          text: "",
          value: "actions"
        }
      ],

      currentFile: null,
      isLoadingDownloadFileId: null,
      files: [],
      URL_API: {
        GET_ALL: `/suppliers/${this.supplier.id}/files`
      }
    }
  },

  methods: {

    toggleEditDialog(val, currentItem = null, needRefresh = false) {
      this.currentFile = currentItem;
      if (needRefresh)
        this.searchTrigger();
      this.showDialog.edit = val;
    },

    toggleImportDialog(val, needRefresh = false) {
      if (needRefresh)
        this.searchTrigger();

      this.showDialog.import = val;
    },

    toggleDeleteDialog(val, currentItem = null, needRefresh = false) {
      this.currentFile = currentItem;
      if (needRefresh)
        this.searchTrigger();
      this.showDialog.delete = val;
    },

    deleteFile() {

      this.isLoadingDownloadFileId = this.currentFile.id;
      this.$http
        .delete(`suppliers/${this.supplier.id}/files/${this.isLoadingDownloadFileId}`, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res => {
          this.toggleDeleteDialog(false, null, true);
        })
        .catch(err => {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
        .finally(() => {
          this.isLoadingDownloadFileId = null;
        })
    },

    download(file) {

      this.isLoadingDownloadFileId = file.id;

      this.$http
        .get(`suppliers/${this.supplier.id}/files/${this.isLoadingDownloadFileId}/download`, {
          responseType: 'arraybuffer',
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.originalname);
          //document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(err => {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
        .finally(() => {
          this.isLoadingDownloadFileId = null;
        })
    },
  }
}
</script>

<style scoped>

</style>
